html {
  font-size: 62.5%;
  scroll-padding-top: 9.5rem;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 1.6rem;
}

* {
  box-sizing: border-box;
}

#root,
menu,
button {
  mat-icon {
    font-size: 1.8rem !important;
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}
