// page setup
sss-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#footer {
  margin-top: auto;
}

// components
.page-section {
  display: flex;
  justify-content: center;
  padding: 5rem;
  h1 {
    font-weight: 900;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 160rem; // ~1600px
  }
}

.card {
  padding: 1rem 2rem;
  border-radius: 3px;
  box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.25);
}

.legal-section {
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    width: 100%;
  }
}

// ~ 920px
@media (max-width: 57.5rem) {
  .page-section {
    padding: 5rem 2rem;
  }
}
